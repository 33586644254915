import React from 'react';
import Image from 'next/image';
import PropTypes from 'prop-types';

import { withTranslation } from 'language/i18n';

const Counter = ({ t, data, classNameForBG }) => {
  const counts = (count, text, icon, hoverIcon) => (
    <div className="col-15 col-lg-3 mb-30">
      <a className="homepage-counter-container">
        <div className="homepage-counter-head-image">
          <div className="homepage-counter-icon">
            <Image
              src={icon}
              width="25"
              height="21"
            />
          </div>
          <div className="homepage-counter-icon-hover">
            <Image
              src={hoverIcon}
              width="25"
              height="21"
            />
          </div>
        </div>
        <h5 className="homepage-counter-title">{count}</h5>
        <p className="homepage-counter-description">{text}</p>
      </a>
    </div>
  );

  return (
    <section className={classNameForBG}>
      <h2 className="section__header__title section__header__title--mb-45">{t('iyiMakinaInNumbers')}</h2>
      <div className="container">
        <div className="row row--mb30 features">
          {counts(data.peoplesReachedCount, 'Kaç kişiye ulaşıyoruz?', '/image/icons/user-group-yellow.svg', '/image/icons/user-group-black.svg')}
          {counts(data.receivedMoneyOffer, 'Kaç paralık teklif aldık?', '/image/icons/money-yellow.svg', '/image/icons/money-black.svg')}
          {counts(data.receivedOffer, 'Kaç teklif aldık?', '/image/icons/mace-yellow.svg', '/image/icons/mace-black.svg')}
          {counts(data.videoPhotoTakenCount, 'Kaç fotoğraf+video çektik?', '/image/icons/camera-yellow.svg', '/image/icons/camera-black.svg')}
          {counts(data.sentMailSmsCount, 'Kaç SMS/e-posta gönderdik?', '/image/icons/letter-yellow.svg', '/image/icons/letter-black.svg')}
        </div>
      </div>
    </section>
  );
};

Counter.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object,
  classNameForBG: PropTypes.string,
};

export default withTranslation('common')(Counter);
